import styled from '@emotion/styled';
import { Drawer } from '@mui/material';

export const StyledChatDrawer = styled(Drawer, {
    shouldForwardProp: prop => !['fontFamily', 'horizontalMargin', 'verticalMargin'].includes(prop)
  })`
  .MuiDrawer-paper {
    position: fixed;
    font-family: ${({ fontFamily }) => fontFamily};
    width: 100%;
    max-width: 500px;
    min-width: 500px;
    height: 100%;
    max-height: 800px;
    border-radius: 10px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
    border: none;
    top: auto;
    ${({ anchor, horizontalMargin, verticalMargin }) => {
      const sideStyles = {
        left: `
          left: ${horizontalMargin}px;
          @media (max-width: 500px) {
            left: 0;
            min-width: 100%;
          }
          @media (min-height: 800px) {
            bottom: ${verticalMargin}px;
          }
        `,
        right: `
          right: ${horizontalMargin}px;
          @media (max-width: 500px) {
            right: 0;
            min-width: 100%;
          }
          @media (min-height: 800px) {
            bottom: ${verticalMargin}px;
          }
        `,
      };
      return sideStyles[anchor];
    }}

    /* Media queries for responsive behavior */
    @media (max-width: 500px) {
      width: 100%;
      height: 100%;
      max-height: 100%;
      border-radius: 0;
      max-width: none;
      min-width: 100%;
    }
  }
`;
