import styled from '@emotion/styled';
import { Fab, Box } from '@mui/material';
import { keyframes } from '@emotion/react';

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
`;

export const StyledChatFab = styled(Fab, {
  shouldForwardProp: prop => !['side', 'horizontalMargin', 'verticalMargin', 'color'].includes(prop)
})`
  position: fixed;
  bottom: ${({ verticalMargin }) => verticalMargin}px;
  ${({ side, horizontalMargin }) => {
    const sideStyles = {
      left: `left: ${horizontalMargin}px;`,
      right: `right: ${horizontalMargin}px;`,
    };
    return sideStyles[side];
  }}
  animation: ${pulseAnimation} 2s infinite;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
    animation: none;
  }

  /* Increase FAB size on desktop screens (width > 500px) */
  @media (min-width: 501px) {
    width: 70px;
    height: 70px;
  }
`;


export const UnreadIndicator = styled(Box)`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
  font-weight: bold;
`;
