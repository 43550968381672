const getConfigByClientId = async (clientId) => {
  const configurations = [
    {
      clientId: 'elke_A',
      apiEndpoint: 'https://api.aivoorimpact.nl/leerkracht/',
      // apiEndpoint: 'http://localhost/leerkracht/',
      customization: {
        primaryBackgroundColor: '#ffffff',
        primaryTextColor: '#31373d',
        secondaryBackgroundColor: '#34a3da',
        secondaryTextColor: '#ffffff',
        messageListBackgroundColor: '#f5f5f5',
        fontFamily: 'Arial, sans-serif',
        assistantPicture: 'https://chat-interface.aivoorimpact.nl/images/leerkracht_assistent.png',
        assistantName: 'Elke',
        popupMessage: 'Hoi! Waar kan ik je mee helpen?',
        welcomeMessage: 'Hoi! Ik ben **Elke**, de AI-assistent van Stichting leerKRACHT. Ik geef graag antwoord op al je vragen.\n\nKomen we er samen niet uit? Dan kan je ook [persoonlijk contact met ons](https://stichting-leerkracht.nl/contact/) opnemen.\n\nWaar kan ik je mee helpen?',
        privacyMessage: 'Door met Elke te chatten, ga je akkoord met onze [privacyverklaring](https://stichting-leerkracht.nl/privacy-statement/).',
        openButtonSide: 'left',
        openButtonHorizontalMargin: 20,
        openButtonVerticalMargin: 20,
        roleRequired: false,
        maxInputLength: 500,
        roleOptions: [
          { 
            label: 'Leraar',
            value: 'leraar',
            message: 'Welkom, leraar! Als leraar ben je de sleutel tot kwaliteitsverbetering in het onderwijs. Op onze [speciale pagina voor leraren](https://stichting-leerkracht.nl/leerkrachtvoorleraren/) vind je meer informatie over hoe je samen met je team elke dag een beetje beter kunt worden. Je kunt ook meer lezen over [het leerKRACHT-programma](https://stichting-leerkracht.nl/leerkracht-programma/) waarmee je samen met je collega\'s werkt aan beter onderwijs. Waar kan ik je mee helpen?'
          },
          { 
            label: 'Schoolleider',
            value: 'schoolleider',
            message: 'Welkom, schoolleider! Als schoolleider sta je voor de uitdaging om met je team een continue verbetercultuur te creëren. Bekijk onze [speciale pagina voor schoolleiders](https://stichting-leerkracht.nl/leerkrachtvoorschoolleiders/) voor meer informatie over het leerKRACHT-programma. Ook hebben we een interessant artikel over [je cruciale rol als schoolleider](https://stichting-leerkracht.nl/kennisbank/rol-van-schoolleider/) in het creëren van een verbetercultuur. Waar kan ik je mee helpen?'
          },
          { 
            label: 'Bestuurder',
            value: 'bestuurder',
            message: 'Welkom, bestuurder! Als bestuurder wil je waarschijnlijk weten hoe je de kwaliteit van het onderwijs in je stichting kunt verbeteren. Bekijk onze [speciale pagina voor bestuurders](https://stichting-leerkracht.nl/bestuurders/) voor meer informatie over het creëren van een verbetercultuur. Ook kun je je verdiepen in ons [intersectoraal leiderschapsprogramma](https://stichting-leerkracht.nl/besturenprogramma/) waar je kunt leren van Nederlandse topinstellingen. Waar kan ik je mee helpen?'
          },
        ],
      },
    },
    {
      clientId: 'elke_B',
      apiEndpoint: 'https://api.aivoorimpact.nl/leerkracht/',
      // apiEndpoint: 'http://localhost/leerkracht/',
      customization: {
        primaryBackgroundColor: '#ffffff',
        primaryTextColor: '#31373d',
        secondaryBackgroundColor: '#34a3da',
        secondaryTextColor: '#ffffff',
        messageListBackgroundColor: '#f5f5f5',
        fontFamily: 'Arial, sans-serif',
        assistantPicture: 'https://chat-interface.aivoorimpact.nl/images/leerkracht_assistent.png',
        assistantName: 'Elke',
        popupMessage: 'Hoi! Waar kan ik je mee helpen?',
        welcomeMessage: 'Hoi! Ik ben **Elke**, de AI-assistent van Stichting leerKRACHT. Ik geef graag antwoord op al je vragen.\n\nKomen we er samen niet uit? Dan kan je ook [persoonlijk contact met ons](https://stichting-leerkracht.nl/contact/) opnemen.\n\nWaar kan ik je mee helpen?',
        privacyMessage: 'Door met Elke te chatten, ga je akkoord met onze [privacyverklaring](https://stichting-leerkracht.nl/privacy-statement/).',
        openButtonSide: 'left',
        openButtonHorizontalMargin: 20,
        openButtonVerticalMargin: 20,
        roleRequired: false,
        maxInputLength: 500,
        roleOptions: [
          {
            label: 'PO',
            value: 'PO',
            message: 'Welkom, medewerker in het primair onderwijs! Jij staat dagelijks in de klas of ondersteunt de leraren om het beste uit je leerlingen te halen. Op onze [speciale pagina voor het primair onderwijs](https://stichting-leerkracht.nl/primair-onderwijs/) vind je meer informatie over hoe leerKRACHT jou kan helpen om samen met je team elke dag een beetje beter onderwijs te realiseren. Waar kan ik je mee helpen?'
          },        
          {
            label: 'VO',
            value: 'VO',
            message: 'Welkom, medewerker in het voortgezet onderwijs! Elke dag werk jij aan het voorbereiden van je leerlingen op hun vervolgopleiding en toekomst. Op onze [speciale pagina voor het voortgezet onderwijs](https://stichting-leerkracht.nl/voortgezet-onderwijs/) vind je meer informatie over hoe leerKRACHT helpt een cultuur van samenwerking, eigenaarschap en continue verbetering te creëren in jouw team. Waar kan ik je mee helpen?'
          },
          {
            label: 'MBO',
            value: 'MBO',
            message: 'Welkom, medewerker in het beroepsonderwijs! Vanuit jouw passie werk je dagelijks aan de ontwikkeling van studenten en zorg je ervoor dat ze goed voorbereid zijn op de beroepspraktijk. Op onze [speciale pagina voor het mbo](https://stichting-leerkracht.nl/beroepsonderwijs/) vind je inspiratie en tools om samen met je team het onderwijs continu te verbeteren en studenten meer eigenaarschap te geven over hun leerproces. Waar kan ik je mee helpen?'
          }          
        ],
      },
    },
    {
      clientId: 'doorloopjes_assistent',
      apiEndpoint: 'https://api.aivoorimpact.nl/doorloopjes/',
      // apiEndpoint: 'http://localhost/doorloopjes/',
      customization: {
        primaryBackgroundColor: '#ffffff',
        primaryTextColor: '#31373d',
        secondaryBackgroundColor: '#fec91c',
        secondaryTextColor: '#000000',
        messageListBackgroundColor: '#f5f5f5',
        fontFamily: 'Arial, sans-serif',
        assistantPicture: 'https://chat-interface.aivoorimpact.nl/images/doorloopjes_assistent.png',
        assistantName: 'Doorloopjes Assistent',
        popupMessage: 'Hoi! Waar kan ik je mee helpen?',
        welcomeMessage: 'Welkom, ik ben de Doorloopjes-assistent. Ik ben hier om je te helpen bij het vinden van de juiste Doorloopjes en kan jou van informatie voorzien over evidence informed werken.',
        privacyMessage: 'Door met de Doorloopjes Assistent te chatten, ga je akkoord met onze [privacyverklaring](https://stichting-leerkracht.nl/privacy-statement/).',
        openButtonSide: 'left',
        openButtonHorizontalMargin: 20,
        openButtonVerticalMargin: 20,
        roleRequired: false,
        maxInputLength: 500,
        roleOptions: null,
      },
    },
    {
      clientId: 'groeikracht_academie',
      apiEndpoint: 'https://api.aivoorimpact.nl/groeikracht_academie/',
      // apiEndpoint: 'http://localhost/groeikracht_academie/',
      customization: {
        primaryBackgroundColor: '#ffffff',
        primaryTextColor: '#31373d',
        secondaryBackgroundColor: '#34a3da',
        secondaryTextColor: '#ffffff',
        messageListBackgroundColor: '#f5f5f5',
        fontFamily: 'Arial, sans-serif',
        assistantPicture: 'https://chat-interface.aivoorimpact.nl/images/groeikracht_academie_assistent.png',
        assistantName: 'Groeikracht Academie - AI assistent',
        popupMessage: 'Hoi! Waar kan ik je mee helpen?',
        welcomeMessage: 'Hoi! Ik geef graag antwoord op al je vragen over de Groeikracht Academie van leerKRACHT.\n\nVoordat je jouw vraag stelt, wil ik je erop wijzen dat ik een kunstmatige intelligentie ben. Komen we er samen niet uit? Dan kan je ook [persoonlijk contact met ons](https://groeikracht-academie.nl/hulpvraag/) opnemen.\n\nWaar kan ik je mee helpen?',
        privacyMessage: 'Door met de Groeikracht Academie assistent te chatten, ga je akkoord met onze [privacyverklaring](https://stichting-leerkracht.nl/privacy-statement/).',
        openButtonSide: 'right',
        openButtonHorizontalMargin: 20,
        openButtonVerticalMargin: 20,
        roleRequired: true,
        maxInputLength: 500,
        roleOptions: [
          { label: 'Leraar', value: 'leerkracht', message: 'Welkom, leraar! Waar kan ik je mee helpen?' },
          { label: 'Schoolcoach', value: 'schoolcoach', message: 'Welkom, schoolcoach! Waar kan ik je mee helpen?' },
          { label: 'Expertcoach', value: 'expertcoach', message: 'Welkom, expertcoach! Waar kan ik je mee helpen?' },
          { label: 'Verandercoach', value: 'verandercoach', message: 'Welkom, verandercoach! Waar kan ik je mee helpen?' },
          { label: 'Schoolleider', value: 'schoolleider', message: 'Welkom, schoolleider! Waar kan ik je mee helpen?' },
        ],
      },
    },
    {
      clientId: 'online_academie_assistent',
      apiEndpoint: 'https://api.aivoorimpact.nl/online_academie/',
      // apiEndpoint: 'http://localhost/online_academie/',
      customization: {
        primaryBackgroundColor: '#ffffff',
        primaryTextColor: '#31373d',
        secondaryBackgroundColor: '#34a3da',
        secondaryTextColor: '#ffffff',
        messageListBackgroundColor: '#f5f5f5',
        fontFamily: 'Arial, sans-serif',
        assistantPicture: 'https://chat-interface.aivoorimpact.nl/images/online_academie_assistent.png',
        assistantName: 'Online Academie assistent',
        popupMessage: 'Hoi! Waar kan ik je mee helpen?',
        welcomeMessage: 'Hoi! Ik geef graag antwoord op al je vragen over de Online Academie van leerKRACHT.\n\nVoordat je jouw vraag stelt, wil ik je erop wijzen dat ik een kunstmatige intelligentie ben. Komen we er samen niet uit? Dan kan je ook [persoonlijk contact met ons](https://leerkracht-academie.nl/contact/) opnemen.\n\nWaar kan ik je mee helpen?',
        privacyMessage: 'Door met de Online Academie assistent te chatten, ga je akkoord met onze [privacyverklaring](https://stichting-leerkracht.nl/privacy-statement/).',
        openButtonSide: 'right',
        openButtonHorizontalMargin: 20,
        openButtonVerticalMargin: 20,
        roleRequired: true,
        maxInputLength: 500,
        roleOptions: [
          { label: 'Leraar', value: 'leerkracht', message: 'Welkom, leraar! Waar kan ik je mee helpen?' },
          { label: 'Schoolcoach', value: 'schoolcoach', message: 'Welkom, schoolcoach! Waar kan ik je mee helpen?' },
          { label: 'Expertcoach', value: 'expertcoach', message: 'Welkom, expertcoach! Waar kan ik je mee helpen?' },
          { label: 'Verandercoach', value: 'verandercoach', message: 'Welkom, verandercoach! Waar kan ik je mee helpen?' },
          { label: 'Schoolleider', value: 'schoolleider', message: 'Welkom, schoolleider! Waar kan ik je mee helpen?' },
          { label: 'Senior Schoolcoach', value: 'senior_schoolcoach', message: 'Welkom, senior schoolcoach! Waar kan ik je mee helpen?' },
          { label: 'Senior Schoolleider', value: 'senior_schoolleider', message: 'Welkom, senior schoolleider! Waar kan ik je mee helpen?' },
        ],
      },
    },
    {
      clientId: 'elkerliek',
      apiEndpoint: 'https://api.aivoorimpact.nl/lampie/',
      // apiEndpoint: 'http://localhost/lampie/',
      customization: {
        primaryBackgroundColor: '#ffffff',
        primaryTextColor: '#31373d',
        secondaryBackgroundColor: '#34a3da',
        secondaryTextColor: '#ffffff',
        messageListBackgroundColor: '#f5f5f5',
        fontFamily: 'Arial, sans-serif',
        assistantPicture: 'https://chat-interface.aivoorimpact.nl/images/je_leefstijl_als_medicijn_assistent.png',
        assistantName: 'Lampie - AI assistent',
        popupMessage: 'Hoi! Waar kan ik je mee helpen?',
        welcomeMessage: 'Hoi! Ik ben **Lampie**. Ik ben een AI-assistent die je kan helpen met informatie over leefstijlonderwerpen zoals voeding, beweging en slaap.\n\nIk kan je geen medisch advies geven. Voor medische vragen raadpleeg altijd een gekwalificeerde zorgprofessional.\n\nIk maak gebruik van kunstmatige intelligentie voor het beantwoorden van jouw vraag. Meer informatie hierover kan je lezen in de [privacyverklaring](https://www.elkerliek.nl/elkerliek/contact/privacy-gegevens).\n\nWaar kan ik je mee helpen?',
        privacyMessage: 'Door met Lampie te chatten, ga je akkoord met onze [privacyverklaring](https://www.elkerliek.nl/elkerliek/contact/privacy-gegevens). Ben je benieuwd hoe Lampie werkt? Bekijk dan [deze pagina](https://jeleefstijlalsmedicijn.nl/zo-werkt-lampie-de-ai-assistent/).',
        openButtonSide: 'left',
        openButtonHorizontalMargin: 20,
        openButtonVerticalMargin: 20,
        roleRequired: false,
        maxInputLength: 500,
        roleOptions: null,
      },
    },
    {
      clientId: 'gezondhelmond',
      apiEndpoint: 'https://api.aivoorimpact.nl/lampie/',
      // apiEndpoint: 'http://localhost/lampie/',
      customization: {
        primaryBackgroundColor: '#ffffff',
        primaryTextColor: '#31373d',
        secondaryBackgroundColor: '#34a3da',
        secondaryTextColor: '#ffffff',
        messageListBackgroundColor: '#f5f5f5',
        fontFamily: 'Arial, sans-serif',
        assistantPicture: 'https://chat-interface.aivoorimpact.nl/images/je_leefstijl_als_medicijn_assistent.png',
        assistantName: 'Lampie - AI assistent',
        popupMessage: 'Hoi! Waar kan ik je mee helpen?',
        welcomeMessage: 'Hoi! Ik ben **Lampie**. Ik ben een AI-assistent die je kan helpen met informatie over leefstijlonderwerpen zoals voeding, beweging en slaap. Ik kan ook informatie geven over leefstijlprofessionals en -initiatieven in jouw regio.\n\nIk kan je geen medisch advies geven. Voor medische vragen raadpleeg altijd een gekwalificeerde zorgprofessional.\n\nIk maak gebruik van kunstmatige intelligentie voor het beantwoorden van jouw vraag. Komen we er samen niet uit? Dan kan je ook [persoonlijk contact](https://gezondhelmond.nl/contact-gezond-helmond/) met ons opnemen.\n\nWaar kan ik je mee helpen?',
        privacyMessage: 'Door met Lampie te chatten, ga je akkoord met onze [privacyverklaring](https://jeleefstijlalsmedicijn.nl/privacyverklaring/). Ben je benieuwd hoe Lampie werkt? Bekijk dan [deze pagina](https://jeleefstijlalsmedicijn.nl/zo-werkt-lampie-de-ai-assistent/).',
        openButtonSide: 'left',
        openButtonHorizontalMargin: 20,
        openButtonVerticalMargin: 20,
        roleRequired: false,
        maxInputLength: 500,
        roleOptions: null,
      },
    },
    {
      clientId: 'gezondland',
      apiEndpoint: 'https://api.aivoorimpact.nl/lampie/',
      // apiEndpoint: 'http://localhost/lampie/',
      customization: {
        primaryBackgroundColor: '#ffffff',
        primaryTextColor: '#31373d',
        secondaryBackgroundColor: '#34a3da',
        secondaryTextColor: '#ffffff',
        messageListBackgroundColor: '#f5f5f5',
        fontFamily: 'Arial, sans-serif',
        assistantPicture: 'https://chat-interface.aivoorimpact.nl/images/je_leefstijl_als_medicijn_assistent.png',
        assistantName: 'Lampie - AI assistent',
        popupMessage: 'Hoi! Waar kan ik je mee helpen?',
        welcomeMessage: 'Hoi! Ik ben **Lampie**. Ik ben een AI-assistent die je kan helpen met informatie over leefstijlonderwerpen zoals voeding, beweging en slaap. Ik kan ook informatie geven over leefstijlprofessionals en -initiatieven in jouw regio.\n\nIk kan je geen medisch advies geven. Voor medische vragen raadpleeg altijd een gekwalificeerde zorgprofessional.\n\nIk maak gebruik van kunstmatige intelligentie voor het beantwoorden van jouw vraag. Komen we er samen niet uit? Dan kan je ook [persoonlijk contact](mailto:info@gezondland.nl) met ons opnemen.\n\nWaar kan ik je mee helpen?',
        privacyMessage: 'Door met Lampie te chatten, ga je akkoord met onze [privacyverklaring](https://jeleefstijlalsmedicijn.nl/privacyverklaring/). Ben je benieuwd hoe Lampie werkt? Bekijk dan [deze pagina](https://jeleefstijlalsmedicijn.nl/zo-werkt-lampie-de-ai-assistent/).',
        openButtonSide: 'left',
        openButtonHorizontalMargin: 20,
        openButtonVerticalMargin: 20,
        roleRequired: false,
        maxInputLength: 500,
        roleOptions: null,
      },
    },
    {
      clientId: 'jeleefstijlalsmedicijn',
      apiEndpoint: 'https://api.aivoorimpact.nl/lampie/',
      // apiEndpoint: 'http://localhost/lampie/',
      customization: {
        primaryBackgroundColor: '#ffffff',
        primaryTextColor: '#31373d',
        secondaryBackgroundColor: '#34a3da',
        secondaryTextColor: '#ffffff',
        messageListBackgroundColor: '#f5f5f5',
        fontFamily: 'Arial, sans-serif',
        assistantPicture: 'https://chat-interface.aivoorimpact.nl/images/je_leefstijl_als_medicijn_assistent.png',
        assistantName: 'Lampie - AI assistent',
        popupMessage: 'Hoi! Waar kan ik je mee helpen?',
        welcomeMessage: 'Hoi! Ik ben **Lampie**. Ik ben een AI-assistent die je kan helpen met informatie over leefstijlonderwerpen zoals voeding, beweging en slaap. Ik kan ook informatie geven over leefstijlprofessionals en -initiatieven in jouw regio.\n\nIk kan je geen medisch advies geven. Voor medische vragen raadpleeg altijd een gekwalificeerde zorgprofessional.\n\nIk maak gebruik van kunstmatige intelligentie voor het beantwoorden van jouw vraag. Komen we er samen niet uit? Dan kan je ook [persoonlijk contact](https://www.jeleefstijlalsmedicijn.nl/contact/) met ons opnemen.\n\nWaar kan ik je mee helpen?',
        privacyMessage: 'Door met Lampie te chatten, ga je akkoord met onze [privacyverklaring](https://jeleefstijlalsmedicijn.nl/privacyverklaring/). Ben je benieuwd hoe Lampie werkt? Bekijk dan [deze pagina](https://jeleefstijlalsmedicijn.nl/zo-werkt-lampie-de-ai-assistent/).',
        openButtonSide: 'left',
        openButtonHorizontalMargin: 20,
        openButtonVerticalMargin: 20,
        roleRequired: false,
        maxInputLength: 500,
        roleOptions: null,
      },
    },
    {
      clientId: 'samen_zoetermeer_gezond',
      apiEndpoint: 'https://api.aivoorimpact.nl/samen_zoetermeer_gezond/',
      // apiEndpoint: 'http://localhost/samen_zoetermeer_gezond/',
      customization: {
        primaryBackgroundColor: '#ffffff',
        primaryTextColor: '#31373d',
        secondaryBackgroundColor: '#34a3da',
        secondaryTextColor: '#ffffff',
        messageListBackgroundColor: '#f5f5f5',
        fontFamily: 'Arial, sans-serif',
        assistantPicture: 'https://chat-interface.aivoorimpact.nl/images/samen_zoetermeer_gezond.png',
        assistantName: 'Merel - AI assistent',
        popupMessage: 'Hoi! Waar kan ik je mee helpen?',
        welcomeMessage: 'Hoi! Ik ben **Merel**, de AI-assistent van Samen ZoeterMeer Gezond. Ik geef graag antwoord op al je vragen.\n\nKomen we er samen niet uit? Dan kan je ook [persoonlijk contact met ons](https://samenzoetermeergezond.nl/contact/) opnemen.\n\nWaar kan ik je mee helpen?',
        privacyMessage: 'Door met Merel te chatten, ga je akkoord met onze [privacyverklaring]().',
        openButtonSide: 'left',
        openButtonHorizontalMargin: 20,
        openButtonVerticalMargin: 20,
        roleRequired: false,
        maxInputLength: 500,
        roleOptions: null,
      },
    },
  ];

  const config = configurations.find(c => c.clientId === clientId);
  if (!config) {
    throw new Error('Configuration not found for client ID: ${clientId}');
  }
  return config;
};

export default getConfigByClientId;
